<template>
	<div class="order_detail">
		<top-search @changeMode="changeMode"></top-search>
		<div class="process">
			<div class="item" :class="index <= trade_progress_index ?'active':''" v-for="(item,index) in process_list" :key="index">
				<div class="top">
					<div class="line"></div>
					<div class="center"><div>{{index+1}}</div></div>
					<div class="line"></div>
				</div>
				<div class="tit">{{item}}</div>
			</div>
		</div>
		<div class="detail_info" v-loading="detail_loading">
			<div class="goods">
				<div class="info" v-if="order_detail.order_goods">
					<div class="top">
						<div class="number">编号：{{order_detail.order_goods[0].goods_code}}</div>
						<div class="time">{{$util.timeStampTurnTime(order_detail.order_goods[0].modify_time).split(' ')[0].replaceAll('-','/')}}</div>
					</div>
					<div class="name">{{order_detail.order_goods[0].goods_name}}</div>
					<div class="goods_tags">
						<div class="tag" v-if="order_detail.order_goods[0].agent_member_id != 0">官验</div>
						<div class="tag">{{order_detail.order_goods[0].category_name}}</div>
						<div class="tag" v-for="(aItem,aIndex) in order_detail.order_goods[0].goods_attr_parse" :key="aIndex" v-if="aItem.label_type == 1">{{aItem.attr_value_name}}</div>
					</div>
					<div class="accredits">
						<div class="item" v-for="(aItem,aIndex) in order_detail.order_goods[0].goods_attr_parse" :key="aIndex" v-if="aItem.label_type != 1">{{aItem.attr_value_name}}</div>
					</div>
				</div>
				<div class="middle" v-if="order_detail.order_goods">
					<div class="item" v-for="(item,index) in order_detail.order_goods[0].fee_data" :key="index">
						<div class="left">{{item.title}}</div>
						<input type="number" :readonly="item.readonly" :class="action_enable.indexOf('modifyDetailPrice') == -1?'readpnly':'modify'" class="price input" v-model="item.pay_price" @mouseover="detailPriceRead('over',item,index)" @mouseleave="detailPriceRead('leave',item,index,$event)">
					</div>
					<div class="item">
						<div class="left">待付款</div>
						<input type="text" readonly class="total input" v-model="'￥'+order_detail.pay_money">
					</div>
				</div>
				<template v-if="role=='buyer'">
					<div class="member" v-if="order_detail.customer_agent_info">
						<img class="headimg" @click="toMemberDetail(order_detail.customer_agent_info.member_id)" :src="$img(order_detail.customer_agent_info.headimg)" @error="order_detail.customer_agent_info.headimg = defaultHeadImage " alt="">
						<div class="name">{{order_detail.customer_agent_info.nickname}}</div>
						<div class="bottom_btn">
							<div @click="toViewChat(order_detail.customer_agent_info.member_id)">消息</div>
							<div class="wx">微信<img v-if="order_detail.customer_agent_info.wechat_personal_qrcode" class="wechat_img" :src="$img(order_detail.customer_agent_info.wechat_personal_qrcode)" /></div>
						</div>
					</div>
					<div class="member" v-else-if="order_detail.seller_info">
						<img class="headimg" @click="toMemberDetail(order_detail.seller_info.member_id)" :src="$img(order_detail.seller_info.headimg)" @error="order_detail.seller_info.headimg = defaultHeadImage " alt="">
						<div class="name">{{order_detail.seller_info.nickname}}</div>
						<div class="bottom_btn">
							<div @click="toViewChat(order_detail.seller_info.member_id)">消息</div>
							<div class="wx">微信<img v-if="order_detail.seller_info.wechat_personal_qrcode" class="wechat_img" :src="$img(order_detail.seller_info.wechat_personal_qrcode)" /></div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="member" v-if="order_detail.buyer_info">
						<img class="headimg" @click="toMemberDetail(order_detail.buyer_info.member_id)" :src="$img(order_detail.buyer_info.headimg)" @error="order_detail.buyer_info.headimg = defaultHeadImage " alt="">
						<div class="name">{{order_detail.buyer_info.nickname}}</div>
						<div class="bottom_btn">
							<div @click="toViewChat(order_detail.buyer_info.member_id)">消息</div>
							<div class="wx">微信<img v-if="order_detail.buyer_info.wechat_personal_qrcode" class="wechat_img" :src="$img(order_detail.buyer_info.wechat_personal_qrcode)" /></div>
						</div>
					</div>
				</template>
			</div>
			<div class="detail">
				<div class="item file" v-if="order_detail.order_status_info && order_detail.order_status_info.const != 'WAIT_PAY' ">
					<div class="left">资料</div>
					<div class="right profile">
						<div class="img_area">
							<div class="file_item" v-for="(iItem,iIndex) in order_detail.file_data.file_list" :key="iIndex">
								<div class="img_item" v-if="iItem.file_type=='img'">
									<el-image :src="$util.img(iItem.file_path)" fit="cover" :preview-src-list="order_detail.prev_image_list" ></el-image>
									<div v-if="action_enable.indexOf('deleteFile') != -1" class="el-icon-error" @click="deleteImg(iItem.file_id)"></div>
								</div>
								<div class="img_item pdf_item" v-if="iItem.file_type=='pdf'" @click="openFile(iItem.file_path)">
									PDF
									<div v-if="action_enable.indexOf('deleteFile') != -1" class="el-icon-error" @click.stop="deleteImg(iItem.file_id)"></div>
								</div>
							</div>
							<el-upload v-if="action_enable.indexOf('addFile') != -1" ref="upload" multiple accept=".pdf,.png,.jpg,'.jpeg'" :data="{business_type:'order_file'}" :show-file-list="false" :action="fileUploadActionUrl" list-type="picture-card" :on-success="(file, fileList) => { return handleSuccess(file, fileList,'img'); } " class="add_img">
								<div class="el-icon-plus"></div>
							</el-upload>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="left">备注</div>
					<div class="right">
						<input type="text" :class="order_detail.remark_readonly?'readonly':'modify'" :readonly="order_detail.remark_readonly" v-model="order_detail.remark" @mouseover="remarkRead('over',$event)" @mouseleave="remarkRead('leave',$event)">
					</div>
				</div>
				<div class="setting">
					<div class="left">发票</div>
					<div class="invoice_right">
						<div class="top">
							<div class="btn" :class="is_invoice?'':'active'" @click="chooseIsInvoice(0)">不需要</div>
							<div class="btn" :class="is_invoice?'active':''" @click="chooseIsInvoice(1)">需要</div>
						</div>
						<div class="bottom" >
							<el-form class="invoice_form" :model="invoiceForm" :rules="invoiceRules" ref="invoiceForm" >
								<el-form-item prop="is_tax_invoice" :show-message="false">
									<el-select :disabled="action_enable.indexOf('modifyInvoice') == -1" v-model="invoiceForm.is_tax_invoice" placeholder="发票类型" >
									    <el-option
									      v-for="item in invoiceForm.TaxData"
									      :key="item.value"
									      :label="item.name"
									      :value="item.value">
									    </el-option>
									</el-select>
								</el-form-item>
								<el-form-item prop="invoice_type" :show-message="false">
									<el-select :disabled="action_enable.indexOf('modifyInvoice') == -1" v-model="invoiceForm.invoice_type" placeholder="发票形式" >
									    <el-option
									      v-for="item in invoiceForm.paperData"
									      :key="item.value"
									      :label="item.name"
									      :value="item.value">
									    </el-option>
									</el-select>
								</el-form-item>
								<el-form-item prop="invoice_title_type" v-if="invoiceForm.is_tax_invoice==1" :show-message="false">
									<el-select :disabled="action_enable.indexOf('modifyInvoice') == -1" v-model="invoiceForm.invoice_title_type" placeholder="抬头类型" >
									    <el-option
									      v-for="item in enterpriseData"
									      :key="item.value"
									      :label="item.name"
									      :value="item.value">
									    </el-option>
									</el-select>
								</el-form-item>
								<el-form-item prop="order_invoice_company" v-if="(invoiceForm.invoice_title_type == 2) || invoiceForm.is_tax_invoice==2 " :show-message="false">
									<el-input :disabled="action_enable.indexOf('modifyInvoice') == -1" class="shuru" v-model="invoiceForm.order_invoice_company" placeholder="单位名称"></el-input>
								</el-form-item>
								<el-form-item prop="taxpayer_number" v-if="invoiceForm.invoice_title_type == 2 || invoiceForm.is_tax_invoice==2 " :show-message="false">
									<el-input :disabled="action_enable.indexOf('modifyInvoice') == -1" class="shuru"  v-model="invoiceForm.taxpayer_number" placeholder="纳税人号"></el-input>
								</el-form-item>
								<el-form-item  prop="invoice_title" v-if="invoiceForm.is_tax_invoice == 1 && invoiceForm.invoice_title_type ==1 " :show-message="false">
									<el-input :disabled="action_enable.indexOf('modifyInvoice') == -1" class="shuru"  v-model="invoiceForm.invoice_title" placeholder="姓名"></el-input>
								</el-form-item>
								<el-form-item  prop="invoice_phone" :show-message="false">
									<el-input :disabled="action_enable.indexOf('modifyInvoice') == -1" class="shuru"  v-model="invoiceForm.invoice_phone" placeholder="电话"></el-input>
								</el-form-item>
								<el-form-item  prop="invoice_full_address" v-if="invoiceForm.invoice_type == 1" :show-message="false">
									<el-input :disabled="action_enable.indexOf('modifyInvoice') == -1" class="shuru"  v-model="invoiceForm.invoice_full_address" placeholder="邮寄地址"></el-input>
								</el-form-item>
								<el-form-item  prop="invoice_email" v-if="invoiceForm.invoice_type == 2" :show-message="false">
									<el-input :disabled="action_enable.indexOf('modifyInvoice') == -1" class="shuru"  v-model="invoiceForm.invoice_email" placeholder="接收邮箱"></el-input>
								</el-form-item>
								<el-form-item  prop="invoice_company_address" v-if="invoiceForm.is_tax_invoice == 2" :show-message="false">
									<el-input :disabled="action_enable.indexOf('modifyInvoice') == -1" class="shuru"  v-model="invoiceForm.invoice_company_address" placeholder="单位地址"></el-input>
								</el-form-item>
								<!-- <el-form-item  prop="invoice_company_credit" v-if="invoiceForm.is_tax_invoice == 2">
									<el-input v-model="invoiceForm.invoice_company_credit" placeholder="统一社会信用代码"></el-input>
								</el-form-item> -->
								<el-form-item  prop="invoice_company_number" v-if="invoiceForm.is_tax_invoice == 2" :show-message="false">
									<el-input :disabled="action_enable.indexOf('modifyInvoice') == -1" class="shuru"  v-model="invoiceForm.invoice_company_number" placeholder="账户号码"></el-input>
								</el-form-item>
								<el-form-item  prop="invoice_company_bank" v-if="invoiceForm.is_tax_invoice == 2" :show-message="false">
									<el-input :disabled="action_enable.indexOf('modifyInvoice') == -1" class="shuru" v-model="invoiceForm.invoice_company_bank" placeholder="开户行"></el-input>
								</el-form-item>
								<el-form-item  prop="invoice_content" v-if="invoiceForm.invoice_content_array.length > 0" :show-message="false">
									<el-select :disabled="action_enable.indexOf('modifyInvoice') == -1" v-model="invoiceForm.invoice_content" placeholder="发票内容" >
										<el-option
										  v-for="(item,index) in invoiceForm.invoice_content_array"
										  :key="index"
										  :label="item"
										  :value="item">
										</el-option>
									</el-select>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
				
				<div class="item" v-if="order_detail.order_status_info && order_detail.order_status_info.const == 'LEGAL_CHECK_REFUSE'">
					<div class="left">审核</div>
					<div class="right">
						<div class="status">失败</div>
						<div class="reason">审查原因：{{order_detail.legal_check_remark}}</div>
					</div>
				</div>
				<div class="item">
					<div class="left">金额</div>
					<div class="right">
						<input type="text" readonly v-model="order_detail.order_money">
					</div>
				</div>
				<div class="item" v-if="hasPayAction">
					<div class="left">余额</div>
					<div class="right">
						<div class="balance">{{ Number( Number(member.balance) + Number(member.balance_money) ).toFixed(2) }}</div>
						<div class="type" @click="toRecharge()">充值</div>
					</div>
				</div>
				<div class="item">
					<div class="left">类型</div>
					<div class="right">
						<div class="type" @click="changePayType('full')" :class="order_detail.pay_money_type == 'full'?'active':''">全款</div>
						<div class="type" @click="changePayType('deposit')" :class="order_detail.pay_money_type == 'deposit'?'active':''">定金</div>
					</div>
				</div>
				<template v-if="order_detail.pay_money_type == 'deposit'">
					<div class="item">
						<div class="left">定金</div>
						<div class="right">
							<input type="number" :class="order_detail.deposit_readonly?'readonly':'modify'" :readonly="order_detail.deposit_readonly" v-model="order_detail.deposit_money" @mouseover="depositMoneyRead('over',$event)" @mouseleave="depositMoneyRead('leave',$event)">
						</div>
					</div>
					<div class="item">
						<div class="left">尾款</div>
						<div class="right">
							<input type="text" readonly v-model="order_detail.final_money">
						</div>
					</div>
					<div class="item" v-if="order_detail.refund_status_info && order_detail.refund_status_info.const && order_detail.refund_status_info.const != 'NOT'">
						<div class="left">退款</div>
						<div class="right">
							<input type="text" readonly v-model="order_detail.refund_status_info.name">
						</div>
					</div>
				</template>
				<div class="setting">
					<div class="left">成交经纪人</div>
					<div class="right search">
						<div class="top">
							<div class="agent_info" v-if="order_detail.customer_agent_info">
								<img class="circle" :src="$img(order_detail.customer_agent_info.headimg)" alt="" @error="order_detail.customer_agent_info.headimg = defaultHeadImage ">
								<div class="info">{{order_detail.customer_agent_info.realname ? order_detail.customer_agent_info.realname: order_detail.customer_agent_info.nickname}} {{order_detail.customer_agent_info.mobile}}</div>
							</div>
							<input v-else :readonly="!order_detail.search_agent" type="text" placeholder="手机号" v-model="mobile">
							<div class="btn" v-if="order_detail.search_agent" @click="searchMobile()">搜索</div>
						</div>
					</div>
				</div>
			</div>
			<div class="actions" v-if="( order_detail.order_status_action && order_detail.order_status_action.main.length) || ( order_detail.refund_status_action && order_detail.refund_status_action.length )">
				<div class="btn" v-for="(item,index) in order_detail.refund_status_action" :key="item.action" @click="operateOrder(item.action)">{{item.title}}</div>
				<div class="btn"  v-for="(item,index) in order_detail.order_status_action.main" :key="index" @click="operateOrder(item.action)">{{item.title}}</div>
			</div>
		</div>
		<el-dialog class="preview_img" :visible.sync="dialogVisible"><img  :src="$img(dialogImageUrl)" alt="" /></el-dialog>
		<el-dialog class="pay_qrcode" :show-close="false" :visible.sync="qrcodeShow" :close-on-click-modal="false">
			<div class="tit">{{qrcode_type=='alipay'?'支付宝':'微信'}}<div class="line"></div></div>
			<img class="qrcode" :src="qrcode_img" />
			<div class="tips">打开{{qrcode_type=='alipay'?'支付宝':'微信'}}支付</div>
			<i class="el-icon-error" @click="closePayQrcode()"></i>
		</el-dialog>
		<servicerMessage ref="servicerMessage" class="kefu" :toUid="toUid"></servicerMessage>
	</div>
</template>

<script>
	import { orderDetail, modifyDetailPrice, modifyPayMoneyType, modifyDepositMoney, orderAddFile, orderDeleteFile, modifyRemark, invoiceConfig, modifyInvoice, base64Qrcode, searchMember, modifyCustomerAgent, getPayStatus } from '@/api/resource';
	import topSearch from '@/components/top_search';
	import { mapGetters } from "vuex"
	import orderMethod from '@/views/cms/order_method';
	import Config from '@/utils/config';
	import config from '@/utils/config';
	import servicerMessage from "@/components/message/servicerMessage"
	export default {
		components: {
			topSearch,
			servicerMessage
		},
		mixins: [orderMethod],
		data() {
			return {
				current_mode: 1,
				process_list:[],	
				trade_progress_index:0,
				detail_loading:true,
				order_detail:{},
				action_enable:'',
				imgUploadActionUrl:Config.baseUrl +'mling/api/upload/commonImg',
				fileUploadActionUrl:Config.baseUrl +'mling/api/upload/commonFile',
				dialogVisible:false,
				dialogImageUrl:'',
				id:'',
				role:'',
				toUid:'',
				hasPayAction:false,
				is_invoice:0,
				invoiceForm: {
					TaxData:[],
					paperData:[],
					is_tax_invoice:'',//是否专用发票
					invoice_type: '',//纸质or电子
					invoice_title_type: 1,//个人or企业
					// invoice_content:-1,//发票内容  索引
					invoice_content:'',//发票内容  索引
					invoice_content_array: [],
					order_invoice_company:'',
					taxpayer_number:'',//纳税人识别号
					invoice_title:'',//个人姓名
					invoice_phone:'',
					invoice_full_address:'',
					invoice_email:'',
					invoice_company_address:'',
					// invoice_company_credit:'',
					invoice_company_number:'',
					invoice_company_bank:'',
				},
				enterpriseData: [
					{name: '个人',value: 1},
					{name: '企业',value: 2},
				],
				invoice_data:'',
				invoiceRules:{
					is_tax_invoice:[
						{required: true, message: '请选择发票类型', trigger: 'change'}
					],
					invoice_type:[
						{required: true, message: '请选择发票形式', trigger: 'change'}
					],
					invoice_title_type:[{
						required: true, message: '请选择发票抬头类型', trigger: 'change'
					}],
					invoice_content:[{
						required: true, message: '请选择发票内容', trigger: 'change'
					}],
					invoice_title:[{
						required: true, message: '请填写姓名', trigger: 'blur' 
					}],
					invoice_phone:[{
						required: true, message: '请填写电话', trigger: 'blur' 
					}],
					taxpayer_number:[{
						required: true, message: '请填写纳税人识别号', trigger: 'blur' 
					}],
					invoice_full_address:[{
						required: true, message: '请填写发票邮寄地址', trigger: 'blur' 
					}],
					invoice_email:[{
						required: true, message: '请填写发票接收邮箱', trigger: 'blur' 
					}],
					order_invoice_company:[{
						required: true, message: '请填写单位名称', trigger: 'blur' 
					}],
					invoice_company_address:[{
						required: true, message: '请填写单位地址', trigger: 'blur' 
					}],
					// invoice_company_credit:[{
					// 	required: true, message: '请填写统一社会信用代码', trigger: 'blur' 
					// }],
					invoice_company_number:[{
						required: true, message: '请填写账户号码', trigger: 'blur' 
					}],
					invoice_company_bank:[{
						required: true, message: '请填写开户行', trigger: 'blur' 
					}],
				},
				qrcode_img:'',
				qrcode_type:'',
				qrcodeShow:false,
				agent_info:'',
				mobile:'',
				invoice_watch:false,
				first:true,
				timer:'',
			}
		},
		computed: {
		    ...mapGetters(["token", "defaultHeadImage", "defaultShopImage", "defaultGoodsImage", "addonIsExit", "locationRegion","member"])
		},
		// watch: {
		// 	invoiceForm:{
		// 		handler(newValue,oldValue){
		// 			if( (!this.is_invoice) || (!this.invoice_watch) ) return;
		// 			if(!this.first){
						
		// 			}else{
		// 				this.first = false;
		// 			}
		// 		},
		// 		deep:true
		// 	}
		// },
		created() {
			this.id = this.$route.query.order_id || '';
			this.role = this.$route.query.role || '';
			this.getInvoiceConfig();
		},
		methods:{
			getOrderPayStatus(out_trade_no){
				getPayStatus({
					out_trade_no:out_trade_no
				})
				.then(res=>{
					if(res.code>=0){
						if(res.data.pay_status){
							this.$message.success('支付成功')
							this.qrcodeShow = false;
							this.qrcode_type = '';
							this.getOrderDetail();
							clearTimeout(this.timer);
						}else{
							this.timer = setTimeout(()=>{
								this.getOrderPayStatus(out_trade_no);
							},1000)
						}
					}else{
						this.$message.error(res.message);
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
			},
			searchMobile(){
				var phoneReg = /^1[3-9]\d{9}$/;
				if(!this.mobile){
					this.$message.error('请输入手机号码');
					return;
				}
				if(!phoneReg.test(this.mobile)){
					this.$message.error('手机号码格式不正确');
					return;
				}
				if(this.flag) return;
				this.flag = true;
				searchMember({
					mobile:this.mobile,
					is_agent:1
				})
				.then(res=>{
					if(res.code>=0){
						if(res.data){
							modifyCustomerAgent({
								order_id:this.id,
								customer_agent_member_id:res.data.member_id
							})
							.then(res=>{
								if(res.code>=0){
									this.flag = false;
									this.getOrderDetail();
								}else{
									this.$message.error(res.message);
									this.flag = false;
								}
							})
							.catch(err=>{
								this.$message.error(err.message);
								this.flag = false;
							})
						}else{
							this.$message.error('查找不到对应的经纪人');
							this.flag = false;
						}
					}else{
						this.$message.error(res.message);
						this.flag = false;
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
					this.flag = false;
				})
			},
			closePayQrcode(){
				this.qrcodeShow = false;
			},
			chooseIsInvoice(mode){
				if(this.action_enable.indexOf('modifyInvoice') == -1){
					return;
				}
				this.is_invoice = mode;
					//验证
				if(this.is_invoice && !this.addmemberInvoice('invoiceForm')){
					this.is_invoice = 0 ;
					return;
				}
				modifyInvoice({
					order_id:this.id,
					is_invoice:this.is_invoice,
					invoice_data:this.is_invoice?this.invoice_data:''
				})
				.then(res=>{
					if(res.code>=0){
						this.getOrderDetail();
					}else{
						this.$message.error(res.message);
						this.is_invoice = 0 ;
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
					this.is_invoice = 0 ;
				})
			},
			addmemberInvoice(invoiceForm){
				var phonereg = /^1[3-9]\d{9}$/;
				var emailReg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
				//验证表单
				if(this.invoiceForm.is_tax_invoice == 1 ){
					//普票
					if(!this.invoiceForm.invoice_phone){
						this.$message.error({
							message: '电话不能为空',
							type: "warning"
						})
						return false;
					}
					if(!phonereg.test(this.invoiceForm.invoice_phone)){
						this.$message.error({
							message: '电话格式有误',
							type: "warning"
						})
						return false;
					}
					if(this.invoiceForm.invoice_title_type == 1){
						//个人
						if(!this.invoiceForm.invoice_title){
							this.$message.error({
								message: '姓名不能为空',
								type: "warning"
							})
							return false;
						}
						
					}else{
						//企业
						if(!this.invoiceForm.order_invoice_company){
							this.$message.error({
								message: '单位名称不能为空',
								type: "warning"
							})
							return false;
						}
						if(!this.invoiceForm.taxpayer_number){
							this.$message.error({
								message: '纳税人识别号不能为空',
								type: "warning"
							})
							return false;
						}
					}
				}else{
					//专票
					if(!this.invoiceForm.invoice_phone){
						this.$message.error({
							message: '电话不能为空',
							type: "warning"
						})
						return false;
					}
					if(!phonereg.test(this.invoiceForm.invoice_phone)){
						this.$message.error({
							message: '电话格式有误',
							type: "warning"
						})
						return false;
					}
					if(!this.invoiceForm.order_invoice_company){
						this.$message.error({
							message: '单位名称不能为空',
							type: "warning"
						})
						return false;
					}
					if(!this.invoiceForm.taxpayer_number){
						this.$message.error({
							message: '纳税人识别号不能为空',
							type: "warning"
						})
						return false;
					}
					if(!this.invoiceForm.invoice_company_address){
						this.$message.error({
							message: '单位地址不能为空',
							type: "warning"
						})
						return false;
					}
					// if(!this.invoiceForm.invoice_company_credit){
					// 	this.$message.error({
					// 		message: '统一社会信用代码不能为空',
					// 		type: "warning"
					// 	})
					// 	return false;
					// }
					if(!this.invoiceForm.invoice_company_number){
						this.$message.error({
							message: '账户号码不能为空',
							type: "warning"
						})
						return false;
					}
					if(!this.invoiceForm.invoice_company_bank){
						this.$message.error({
							message: '开户行不能为空',
							type: "warning"
						})
						return false;
					}
				}
				if(this.invoiceForm.invoice_type == 1){
					//纸质
					if(!this.invoiceForm.invoice_full_address){
						this.$message.error({
							message: '发票邮寄地址不能为空',
							type: "warning"
						})
						return false;
					}
				}else{
					//电子
					if(!this.invoiceForm.invoice_email){
						this.$message.error({
							message: '邮箱不能为空',
							type: "warning"
						})
						return false;
					}
					if(!emailReg.test(this.invoiceForm.invoice_email)){
						this.$message.error({
							message: '邮箱格式有误',
							type: "warning"
						})
						return false;
					}
				}
				if(this.invoiceForm.invoice_content === '' ){
					this.$message.error({
						message: '发票内容不能为空',
						type: "warning"
					})
					return false;
				}
				this.invoice_data = JSON.stringify(this.invoiceForm);
				return true;
			},
			getInvoiceConfig(){
				invoiceConfig()
				.then(res=>{
					if(res.code>=0){
						if (res.data.invoice_content) {
							this.invoiceForm.invoice_content_array = res.data.invoice_content
						} else {
							this.invoiceForm.invoice_content_array = [];
						}
						if(res.data.tax_type.length){
							this.invoiceForm.TaxData = [];
							res.data.tax_type.forEach(item=>{
								if(item==1){
									this.invoiceForm.TaxData.push({name: '普通发票',value: 1})
								}
								if(item==2){
									this.invoiceForm.TaxData.push({name: '专用发票',value: 2})
								}
							})
						}
						if(res.data.type.length){
							this.invoiceForm.paperData = [];
							res.data.type.forEach(item=>{
								if(item==1){
									this.invoiceForm.paperData.push({name: '纸质发票',value: 1})
								}
								if(item==2){
									this.invoiceForm.paperData.push({name: '电子发票',value: 2})
								}
							})
						}
						this.getOrderDetail();
						// this.invoiceForm.is_tax_invoice = this.invoiceForm.is_tax_invoice ? this.invoiceForm.is_tax_invoice : this.invoiceForm.TaxData[0].value
						// this.invoiceForm.invoice_type = this.invoiceForm.invoice_type ? this.invoiceForm.invoice_type : this.invoiceForm.paperData[0].value
					}
				})
			},
			toRecharge(){
				this.$router.push('/member/index?isRecharge=1&my_current=9')
			},
			toMemberDetail(id){
				this.$router.push('/personal-'+id);
			},
			toViewChat(id){
				this.toUid = 'member_'+id;
				this.$refs.servicerMessage.show();
			},
			openFile(path){
				window.open(Config.baseUrl + path);
			},
			prevImg(path){
				this.dialogImageUrl = path;
				this.dialogVisible = true;
			},
			deleteImg(id){
				orderDeleteFile({
					order_id:this.id,
					file_id:id
				})
				.then(res=>{
					if(res.code>=0){
						this.$message({message:'删除成功',type:'success'});
						this.getOrderDetail();
					}else{
						this.$message.error(res.message);
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
			},
			handleSuccess(file, fileList,mode) {
				if(file.code<0){
					this.$message.error(file.message);
					return;
				}
				var file_name = file.data.original_name;
				var file_path = file.data.path;
				orderAddFile({
					order_id:this.id,
					file_name:file_name,
					file_path:file_path
				})
				.then(res=>{
					if(res.code>=0){
						this.getOrderDetail();
					}else{
						this.$message.error(res.message);
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
			},
			operateOrder(action){
				switch (action){
					case 'orderClose':
						this.closeOrder(this.id,()=>{
							this.getOrderDetail();
						})
						break;
					case 'orderPay':
						this.payOrder(this.id,()=>{
							this.getOrderDetail();
						})
						break;
					case 'orderWechatPay':
						this.wechatPayOrder(this.id,(res)=>{
							// this.getOrderDetail();
							if(res.data){
								if(res.data.type=='url'){
									
								}else{
									//需要弹出二维码
									this.qrcode_type = 'wechat';
									this.qrcode_img = res.data.qrcode;
									this.qrcodeShow = true;
								}
								this.getOrderPayStatus(res.data.out_trade_no);
							}else{
								this.getOrderDetail();
							}
						})
						break;
					case 'orderAliPay':
						this.aliPayOrder(this.id,(res)=>{
							if(res.data){
								if(res.data.type=='url'){
									base64Qrcode({
										text:res.data.data
									})
									.then(res=>{
										if(res.code>=0){
											this.qrcode_type = 'alipay';
											this.qrcode_img = res.data;
											this.qrcodeShow = true;
										}
									})
								}
								this.getOrderPayStatus(res.data.out_trade_no);
							}else{
								this.getOrderDetail();
							}
						});
						break;
					case 'serviceComplete':
						this.serviceComplete(this.id,()=>{
							this.getOrderDetail();
						})
						break;
					case 'orderFinalPay':
						this.orderFinalPay(this.id,()=>{
							this.getOrderDetail();
						})
						break;
					case 'correctComplete':
						this.correctComplete(this.id,()=>{
							this.getOrderDetail();
						})
						break;
					case 'applyRefund':
						this.applyRefund(this.id,()=>{
							this.getOrderDetail();
						})
						break;
					case 'cancelRefund':
						this.cancelRefund(this.id,()=>{
							this.getOrderDetail();
						})
						break;
					case 'agreeRefund':
						this.agreeRefund(this.id,()=>{
							this.getOrderDetail();
						})
						break;
					case 'refuseRefund':
						this.refuseRefund(this.id,()=>{
							this.getOrderDetail();
						})
						break;
					default:
						break;
				}
			},
			remarkRead(status,e){
				if(this.action_enable.indexOf('modifyRemark') == -1){
					return;
				}
				if(status=='leave'){
					if(this.order_detail.remark_desc_readonly == e.fromElement._value){
						this.order_detail.remark_readonly = true;
						return;
					}
					modifyRemark({
						order_id:this.id,
						remark:e.fromElement._value
					})
					.then(res=>{
						if(res.code>=0){
							this.order_detail.remark_readonly = true;
							this.getOrderDetail();
						}else{
							this.$message.error(res.message);
							this.order_detail.remark = this.order_detail.remark_desc_readonly;
							this.order_detail.remark_readonly = true;
						}
					})
					.catch(err=>{
						this.$message.error(err.message);
						this.order_detail.remark = this.order_detail.remark_desc_readonly;
						this.order_detail.remark_readonly = true;
					})
					this.order_detail.remark_readonly = true;
				}else{
					this.order_detail.remark_readonly = false;
				}
			},
			depositMoneyRead(status,e){
				if(this.action_enable.indexOf('modifyDepositMoney') == -1){
					return;
				}
				if(status=='leave'){
					if(Number(this.order_detail.deposit_money_readonly) == Number(e.fromElement._value)){
						this.order_detail.deposit_readonly = true;
						return;
					}
					if(e.fromElement._value<0){
						this.$message.error('定金不能小于0')
						this.order_detail.deposit_money = this.order_detail.deposit_money_readonly;
						this.order_detail.deposit_readonly = true;
						return;
					}
					modifyDepositMoney({
						order_id:this.id,
						deposit_money:Number(e.fromElement._value).toFixed(2),
					})
					.then(res=>{
						if(res.code>=0){
							this.order_detail.deposit_readonly = true;
							this.getOrderDetail();
						}else{
							this.$message.error(res.message);
							this.order_detail.deposit_money = this.order_detail.deposit_money_readonly;
							this.order_detail.deposit_readonly = true;
						}
					})
					.catch(err=>{
						this.$message.error(err.message);
						this.order_detail.deposit_money = this.order_detail.deposit_money_readonly;
						this.order_detail.deposit_readonly = true;
					})
					this.order_detail.deposit_readonly = true;
				}else{
					this.order_detail.deposit_readonly = false;
				}
			},
			changePayType(mode){
				if(this.action_enable.indexOf('modifyPayMoneyType') == -1){
					return;
				}
				modifyPayMoneyType({
					order_id:this.id,
					pay_money_type:mode
				})
				.then(res=>{
					if(res.code>=0){
						this.getOrderDetail();
					}else{
						this.$message.error(res.message)
					}
				})
			},
			detailPriceRead(status,item,index,e){
				if(this.action_enable.indexOf('modifyDetailPrice') == -1){
					return;
				}
				if(status=='leave'){
					if( Number(item.original_price) == Number(e.fromElement._value) ){
						item.readonly = true;
						return;
					}
					if(e.fromElement._value<0){
						this.$message.error(item.title+'不能小于0')
						item.pay_price = item.original_price;
						item.readonly = true;
						return;
					}
					modifyDetailPrice({
						index:index,
						order_id:this.id,
						price: Number(e.fromElement._value).toFixed(2)
					})
					.then(res=>{
						if(res.code>=0){
							item.readonly = true;
							this.getOrderDetail();
						}else{
							this.$message.error(res.message);
						}
					})
					.catch(err=>{
						this.$message.error(err.message);
					})
					item.readonly = true;
				}else{
					item.readonly = false;
				}
			},
			getOrderDetail(){
				orderDetail({
					order_id:this.id,
					role:this.role
				})
				.then(res=>{
					if(res.code>=0){
						this.order_detail = res.data;
						if(this.order_detail.order_goods[0].goods_attr_format) this.order_detail.order_goods[0].goods_attr_parse = JSON.parse(this.order_detail.order_goods[0].goods_attr_format);
						if(this.order_detail.order_goods[0].extend_data){
							this.order_detail.order_goods[0].extend_data = JSON.parse(this.order_detail.order_goods[0].extend_data);
							
							if(this.order_detail.order_goods[0].extend_data.company_attr_format) this.order_detail.order_goods[0].extend_data.company_attr_format = JSON.parse(this.order_detail.order_goods[0].extend_data.company_attr_format);
							if(this.order_detail.order_goods[0].extend_data.logo_attr_format) this.order_detail.order_goods[0].extend_data.logo_attr_format = JSON.parse(this.order_detail.order_goods[0].extend_data.logo_attr_format);
							if(this.order_detail.order_goods[0].extend_data.company_image) this.order_detail.order_goods[0].extend_data.company_image = this.order_detail.order_goods[0].extend_data.company_image.split(',')
							if(this.order_detail.order_goods[0].extend_data.logo_image) this.order_detail.order_goods[0].extend_data.logo_image = this.order_detail.order_goods[0].extend_data.logo_image.split(',')
						}
						if(this.order_detail.order_goods[0].fee_data){
							this.order_detail.order_goods[0].fee_data = JSON.parse(this.order_detail.order_goods[0].fee_data);
							this.order_detail.order_goods[0].fee_data.forEach(fItem=>{
								fItem.pay_price = Number(fItem.pay_price).toFixed(2);
								this.$set(fItem,'readonly',true);
								this.$set(fItem,'original_price',fItem.pay_price);
							});
						}
						this.order_detail.deposit_money_readonly = this.order_detail.deposit_money;
						this.order_detail.remark_desc_readonly = this.order_detail.remark;
						if(this.order_detail.file_data.img_list.length){
							this.order_detail.prev_image_list = this.order_detail.file_data.img_list.map(el =>{
								return this.$img(el.file_path)
							})
						}
						this.$set(this.order_detail,'deposit_readonly',true);
						this.$set(this.order_detail,'remark_readonly',true);
						var action_enable = [];
						if(this.order_detail.order_status_action.other.length){
							this.order_detail.order_status_action.other.forEach(item=>{
								action_enable.push(item.action);
							})
						}
						if(this.order_detail.trade_progress) this.process_list = JSON.parse(this.order_detail.trade_progress);
						this.trade_progress_index = this.order_detail.trade_progress_index;
						this.action_enable = action_enable.toString();
						this.hasPayAction = false;
						if( this.order_detail.order_status_action && this.order_detail.order_status_action.main ){
							this.order_detail.order_status_action.main.forEach(item=>{
								if(['orderPay','orderFinalPay'].indexOf(item.action) != -1) this.hasPayAction = true;
							})
						}
						//赋值发票信息
						this.is_invoice = res.data.is_invoice;
						this.invoiceForm.is_tax_invoice = res.data.is_tax_invoice ? Number(res.data.is_tax_invoice) : this.invoiceForm.TaxData[0].value;
						this.invoiceForm.invoice_type = res.data.invoice_type ? Number(res.data.invoice_type) : this.invoiceForm.paperData[0].value;
						if(res.data.is_invoice){
							this.invoiceForm.invoice_title_type = Number(res.data.invoice_title_type);
							this.invoiceForm.order_invoice_company = res.data.order_invoice_company;
							this.invoiceForm.taxpayer_number = res.data.taxpayer_number;
							this.invoiceForm.invoice_title = res.data.invoice_title;
							this.invoiceForm.invoice_phone = res.data.invoice_phone;
							this.invoiceForm.invoice_full_address = res.data.invoice_full_address;
							this.invoiceForm.invoice_email = res.data.invoice_email;
							this.invoiceForm.invoice_company_address = res.data.invoice_company_address;
							this.invoiceForm.invoice_company_number = res.data.invoice_company_number;
							this.invoiceForm.invoice_company_bank = res.data.invoice_company_bank;
							this.invoiceForm.invoice_content = res.data.invoice_content;
						}
						this.invoice_watch = true;
						if( (this.order_detail.order_status_info && this.order_detail.order_status_info.const == 'CLOSE') || this.order_detail.customer_agent_info ){
							this.$set(this.order_detail,'search_agent',false);
						}else{
							this.$set(this.order_detail,'search_agent',true);
						}
						this.detail_loading = false;
					}
				})
			},
			changeMode(mode) {
			    this.current_mode = mode
			},
		},
	}
</script>
<style lang="scss">
	.pay_qrcode{
		.el-dialog{
			margin-top: 0 !important;
			top: 50%;
			transform: translateY(-50%);
			max-width: 554px;
			border-radius: 10px;
		}
		.el-dialog__header{
			padding:0;
		}
		.el-dialog__body{
			position: relative;
			padding: 76px 134px 86px;
			border-radius: 10px;
			background-color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.tit{
				width: 100%;
				border-bottom: 1px solid rgba(48, 55, 61, 0.10);
				color: #30373D;
				font-size: 19px;
				line-height: 23px;
				display: flex;
				align-items: center;
				flex-direction: column;
				padding-bottom: 25px;
				position: relative;
				.line{
					width: 34px;
					height: 2px;
					background-color: #30373D;
					border-radius: 6px;
					position: absolute;
					bottom: 0;
					transform: translateY(50%);
				}
			}
			.qrcode{
				width: 224px;
				border-radius: 3px;
				display: block;
				margin-top: 30px;
			}
			.tips{
				margin-top: 30px;
				color: #999;
				font-size: 13px;
				line-height: 15px;
			}
			.el-icon-error{
				color: rgba(48, 55, 61, 0.50);
				font-size: 18px;
				line-height: 18px;
				position: absolute;
				right: 20px;
				top: 20px;
				cursor: pointer;
			}
		}
	}
	.el-image-viewer__canvas{
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 80%;
		height: 80%;
		img{
			object-fit: contain;
		}
	}
	.preview_img{
		img{
			width: 100%;
			max-height: 60vh;
			object-fit: contain;
		}
	}
	.add_file{
		.el-upload--picture-card{
			border: none;
			background-color: #F7F8FB;
			border-radius: 4px;
			width: auto;
			height: auto;
			line-height: 18px;
			padding: 6px 10px;
		}
	}
	.add_img{
		.el-upload--picture-card{
			border: none;
			background-color: #F7F8FB;
			border-radius: 10px;
			height: 101px;
			width: 101px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.invoice_form{
		.shuru{
			border-radius: 5px;
			background-color: #F7F8FB;
			font-family: "PingFang SC";
			line-height: 1;
			padding:10px 0;
			.el-input__inner{
				background-color: transparent;
				border:none;
				text-align: center;
				height: auto;
				padding: 0;
				border-radius: 5px;
				color: #30373D;
				font-size: 15px;
				line-height: 21px;
				&::placeholder{
					font-size: 15px;
					color: rgba(48, 55, 61, 0.50);
					font-family: "PingFang SC";
				}
			}
		}
		.el-select{
			width: 100%;
			background-color: #F7F8FB;
			border-radius: 5px;
			padding: 10px 0;
			.el-input--suffix{
				display: flex;
				align-items: center;
				justify-content: center;
				line-height: 1;
				.el-input__inner{
					width: 25%;
					background-color: transparent;
					border:none;
					padding: 0;
					height: auto;
					text-align: center;
					color: #30373D;
					font-size: 15px;
					line-height: 21px;
					&::placeholder{
						color: rgba(48, 55, 61, 0.50);
						font-family: "PingFang SC";
					}
				}
				.el-input__suffix{
					position: relative;
					right: 0;
					height: auto;
					margin-left: 10px;
					text-align: left;
					.el-input__suffix-inner{
						line-height: 12px;
						display: block;
					}
				}
			}
		}
		
	}
	.el-select-dropdown{
		.el-scrollbar{
			.el-scrollbar__view{
				.el-select-dropdown__item{
					text-align: center;
				}
			}
		}
	}
	.invoice_form .el-select .el-input .el-select__caret {
	  transform: rotateZ(0deg);
	  width: 12px;
	  font-size: 12px;
	  line-height: 1;
	}
	.invoice_form .el-select .el-input .el-select__caret::before {
	  content: "\e790";
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  top: 50%;
	  left: 50%;
	  color: #30373D !important;
	  transform: translate(-50%, -50%);
	}
</style>
<style lang="scss" scoped>
	.order_detail{
		padding-top: 30px;
	}
	.process{
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		.item{
			display: flex;
			flex-direction: column;
			align-items: center;
			&.active{
				.top{
					.line{
						background-color: #5BEBAE;
					}
					.center{
						border: 1px dashed #5BEBAE;
						div{
							background-color: #5BEBAE;
						}
					}
				}
			}
			&:first-child{
				.top{
					>div{
						&:first-child{
							background-color: transparent;
						}
					}
				}
			}
			&:last-child{
				.top{
					>div{
						&:last-child{
							background-color: transparent;
						}
					}
				}
			}
			.tit{
				color: #30373D;
				font-size: 15px;
				line-height: 1;
			}
			.top{
				display: flex;
				align-items: center;
				margin-bottom: 10px;
				.line{
					width: 38px;
					height: 1px;
					background-color: rgba(48, 55, 61, 0.20);
				}
				.center{
					margin: 0 20px;
					padding: 3px;
					border: 1px dashed #5BEBAE;
					border: 1px dashed rgba(48, 55, 61, 0.20);
					border-radius: 50%;
					div{
						padding: 3px 6px 3px 6px;
						border-radius: 50%;
						background-color: #5BEBAE;
						background-color: rgba(48, 55, 61, 0.20);
						color: #fff;
						font-size: 13px;
						line-height: 13px;
					}
				}
			}
			
		}
	}
	.detail_info{
		background-color: #fff;
		.actions{
			border-top: 1px solid rgba(48, 55, 61, 0.10);
			padding: 30px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			.btn{
				color: rgba(48, 55, 61, 0.50);
				font-size: 15px;
				line-height: 21px;
				background-color: #F7F8FB;
				border-radius: 5px;
				padding: 5px 28px 6px;
				cursor: pointer;
				margin-right: 30px;
				&:last-child{
					margin-right: 0;
				}
				&:hover{
					color: #fff;
					background-color: #30373D;
				}
			}
		}
		.detail{
			padding: 51px 30px 30px;
			.setting{
				display: flex;
				align-items: baseline;
				margin-bottom: 30px;
				&:last-child{
					margin-bottom: 0;
				}
				.left{
					color: #30373D;
					font-size: 15px;
					line-height: 21px;
					font-weight: 600;
					margin-right: 30px;
					min-width: 75px;
				}
				.invoice_right{
					width: 410px;
					.top{
						display: flex;
						align-items: center;
						.btn{
							color: #30373D;
							font-size: 13px;
							line-height: 18px;
							background-color: #F7F8FB;
							height: 32px;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 73px;
							text-align: center;
							margin-right: 20px;
							border-radius: 5px;
							cursor: pointer;
							box-sizing: border-box;
							border: none;
							&:last-child{
								margin-right: 0;
							}
							&.active{
								color: #fff;
								background-color: #30373D;
							}
							&.set{
								border: 1px solid rgba(48, 55, 61, 0.2);
							}
						}
					}
					.bottom{
						.invoice_form{
							margin-top: 20px;
							width: 100%;
							.el-form-item{
								margin-bottom: 20px;
							}
						}
					}
				}
				.right{
					display: flex;
					align-items: center;
					.btn{
						color: #30373D;
						font-size: 15px;
						line-height: 21px;
						background-color: #F7F8FB;
						padding: 6px 22px 5px 21px;
						margin-right: 20px;
						border-radius: 5px;
						cursor: pointer;
						box-sizing: border-box;
						border: none;
						&:last-child{
							margin-right: 0;
						}
						&.active{
							color: #fff;
							background-color: #30373D;
						}
						&.set{
							border: 1px solid rgba(48, 55, 61, 0.2);
						}
					}
					&.search{
						display: flex;
						align-items: flex-start;
						flex-direction: column;
						.top{
							display: flex;
							align-items: center;
							.agent_info{
								display: flex;
								align-items: center;
								padding: 6px 10px 5px 19px;
								background-color: #F7F8FB;
								border-radius: 5px;
								margin-right: 15px;
								cursor: pointer;
								&:hover{
									.delete_btn{
										visibility: inherit;
									}
								}
								.circle{
									width: 15px;
									height: 15px;
									border-radius: 50%;
									margin-right: 10px;
									display: block;
									object-fit: cover;
								}
								.info{
									color: #30373D;
									font-size: 15px;
									line-height: 21px;
								}
								.delete_btn{
									color: #999;
									margin-left: 6px;
									visibility: hidden;
								}
							}
							input{
								outline: none;
								border: none;
								border-radius: 5px;
								font-size: 15px;
								line-height: 21px;
								padding: 6px 10px 5px;
								margin-right: 20px;
								background-color: #F7F8FB;
								text-align: center;
								box-sizing: border-box;
								width: 166px;
								&::placeholder{
									color: rgba(48, 55, 61, 0.50);
									font-family: "PingFang SC";
									font-size: 15px;
								}
							}
							.btn{
								color: #30373D;
								font-size: 13px;
								line-height: 18px;
								background-color: #F7F8FB;
								border-radius: 5px;
								margin-right: 20px;
								cursor: pointer;
								width: 73px;
								height: 32px;
								display: flex;
								align-items: center;
								justify-content: center;
								text-align: center;
								padding: 0;
							}
						}
						.result{
							margin-top: 15px;
							padding: 8px 28px 8px 10px;
							border: 1px solid rgba(48, 55, 61, 0.20);
							display: flex;
							align-items: center;
							img{
								width: 55px;
								height: 55px;
								display: block;
								object-fit: cover;
								border-radius: 50%;
								margin-right: 15px;
							}
							.info{
								.name{
									font-size: 13px;
									line-height: 15px;
									margin-bottom: 5px;
									&:last-child{
										margin-bottom: 0;
									}
								}
							}
						}
					}
				}
			}
			.item{
				display: flex;
				align-items: baseline;
				margin-bottom: 30px;
				&:last-child{
					margin-bottom: 0;
				}
				&.file{
					align-items: flex-start;
				}
				.left{
					color: #30373D;
					font-size: 15px;
					line-height: 21px;
					margin-right: 30px;
					font-weight:800;
					min-width: 75px;
					white-space: nowrap;
				}
				.right{
					display: flex;
					align-items: center;
					.balance{
						color: #30373D;
						font-size: 13px;
						line-height: 21px;
						padding: 6px 0 5px;
						border-radius: 5px;
						margin-right: 15px;
						width: 166px;
						text-align: center;
						background-color: #F7F8FB;
						box-sizing: border-box;
					}
					.reason{
						color: rgba(48, 55, 61, 0.50);
						padding: 7px 10px;
						border-radius: 5px;
						background-color: #F7F8FB;
					}
					.status{
						color: #fff;
						font-size: 13px;
						line-height: 18px;
						padding: 7px 21px;
						border-radius: 5px;
						background-color: #FF3300;
						margin-right: 20px;
					}
					.type{
						color: #30373D;
						font-size: 13px;
						line-height: 18px;
						background-color: #F7F8FB;
						border-radius: 5px;
						margin-right: 20px;
						cursor: pointer;
						width: 73px;
						height: 32px;
						display: flex;
						align-items: center;
						justify-content: center;
						text-align: center;
						&:last-child{
							margin-right: 0;
						}
						&.active{
							background-color: #30373D;
							color: #fff;
						}
					}
					&.profile{
						flex-direction: column;
						align-items: flex-start;
						.img_area{
							display: flex;
							flex-wrap: wrap;
							.img_item{
								width: 101px;
								height: 101px;
								position: relative;
								margin-right: 10px;
								margin-bottom: 10px;
								&.pdf_item{
									display: flex;
									align-items: center;
									justify-content: center;
									background-color: #F7F8FB;
									border-radius: 10px;
									cursor: pointer;
									font-weight: 600;
									font-size: 15px;
								}
								&:nth-child(4n){
									margin-right: 0;
								}
								.el-image{
									width: 100%;
									height: 100%;
										border-radius: 10px;
								}
								// img{
								// 	object-fit: cover;
								// 	height: 100%;
								// 	width: 100%;
								// 	border-radius: 10px;
								// 	cursor: pointer;
								// }
								.el-icon-error{
									font-size: 16px;
									z-index: 10;
									position: absolute;
									top: 0;
									right: 0;
									transform: translate(50%,-50%);
									cursor: pointer;
								}
							}
							.add_img{
								.el-icon-plus{
									font-size: 26px;
									line-height: 26px;
								}
							}
						}
						
						.file_area{
							margin-top: 20px;
							display: flex;
							flex-wrap: wrap;
							.file_item{
								color: #30373D;
								font-size: 15px;
								line-height: 18px;
								font-weight: 600;
								display: flex;
								align-items: center;
								margin-right: 15px;
								.btns{
									margin-left: 10px;
									display: flex;
									align-items: center;
									.btn{
										margin-right: 6px;
										color: #30373D;
										color: #FF3300;
										cursor: pointer;
										&:last-child{
											margin-right: 0;
										}
									}
								}
							}
						}
					}
					input{
						flex: 1;
						outline: none;
						border: none;
						padding:0;
						padding-left: 6px;
						color: #30373D;
						font-size: 13px;
						line-height: 18px;
						padding: 7px 0 7px 0;
						background-color: #F7F8FB;
						border-radius: 5px;
						text-align: center;
						width: 166px;
						box-sizing: border-box;
					}
					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
						-webkit-appearance: none;
					}
					input[type="number"]{
						-moz-appearance: textfield;
					}
					.modify{
						&:hover{
							background-color: #F7F8FB;
							color: #30373D;
						}
					}
					
				}
			}
		}
		.goods{
			display: flex;
			align-items: center;
			background-color: #fff;
			padding: 30px 0 32px 30px;
			margin-top: 30px;
			box-shadow: 0px 11px 10px 0px rgba(240,237,246,0.4);
			border-radius: 10px;
			.member{
				width: 303px;
				box-sizing: border-box;
				padding: 25px 30px 23px 30px;
				display: flex;
				flex-direction: column;
				align-items: center;
				position: relative;
				.headimg{
					width: 61px;
					height: 61px;
					object-fit: cover;
					display: block;
					border-radius: 50%;
					margin-bottom: 10px;
				}
				.name{
					color: #30373D;
					font-size: 19px;
					line-height: 39px;
					font-weight: 600;
					overflow: hidden;
					text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;
				}
				.bottom_btn{
					margin-top: 30px;
					display: flex;
					flex-direction: column;
					align-items: center;
					div{
						cursor:pointer;
						color: #fff;
						font-size: 13px;
						line-height: 15px;
						padding: 7px 40px;
						border-radius: 20px;
						background-color: #30373D;
						position: relative;
						&.wx{
							cursor: pointer;
							margin-top: 10px;
							color: #1BCF42;
							background-color: rgba(27, 207, 66, 0.20);
						}
					}
					.wx:hover .wechat_img{
						display: block;
					}
					.wechat_img{
						position: absolute;
						display: none;
						top: 100%;
						left: 50%;
						border-radius: 0;
						transform: translateX(-50%);
						margin-bottom: 0;
					}
				}
			}
			.middle{
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding: 0 30px;
				box-sizing: border-box;
				min-width: 238px;
				min-height: 256px;
				border: 1px solid rgba(48, 55, 61, 0.20);
				border-top: none;
				border-bottom: none;
				.item{
					display: flex;
					width: 100%;
					align-items: baseline;
					margin-bottom: 10px;
					justify-content: space-between;
					.left{
						color: #999;
						font-size: 13px;
						line-height: 22px;
						white-space: nowrap;
					}
					.input{
						width: 100px;
						flex: 1;
						outline: none;
						border: none;
						padding:0;
						color: #999;
						font-size: 13px;
						line-height: 22px;
						padding-left: 5px;
						text-align: right;
					}
					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
						-webkit-appearance: none;
					}
					input[type="number"]{
						-moz-appearance: textfield;
					}
					.modify{
						&:hover{
							background-color: #F7F8FB;
							color: #30373D;
						}
					}
					.total{
						color: #FF3300;
						font-size: 15px;
						line-height: 22px;
					}
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
			.info{
				flex: 1;
				.top{
					display: flex;
					align-items: center;
					margin-bottom: 10px;
					.number{
						color: #999;
						font-size: 13px;
						line-height: 19px;
						margin-right: 30px;
					}
					.time{
						color: #999;
						font-size: 13px;
						line-height: 19px;
					}
				}
				.name{
					color: #30373D;
					font-size: 19px;
					line-height: 39px;
					font-weight: 600;
					margin-bottom: 10px;
				}
				.goods_tags{
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					height: 22px;
					overflow: hidden;
					.tag{
						color: #30373D;
						font-size: 15px;
						line-height: 15px;
						padding: 3px 9px 4px;
						background-color: rgba(48, 55, 61, 0.05);
						border-radius: 3px;
						margin-right: 10px;
						&:last-child{
							margin-right: 0;
						}
					}
				}
				.accredits{
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					align-content: flex-start;
					.item{
						color: #30373D;
						font-size: 15px;
						line-height: 20px;
						padding: 0 4px;
						border: 1px solid rgba(48, 55, 61, 0.20);
						border-radius: 3px;
						margin-right: 10px;
						margin-top: 10px;
					}
				}
			}
		}
	}
</style>